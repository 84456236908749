import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Label } from 'reactstrap';
import { SendProtectiveEquipmentForm } from '../../components/queries/testQueries';
import { useTkalDataContext } from '../../context/tkalData';
import SelectPerson from '../../components/configurationForms/protective-equipment/SelectPerson';
import { ProtectiveEquipment } from '../../types/protective-equipment/ProtectiveEquipment';
import SignatureCanvas from 'react-signature-canvas';

const ProtectiveEquipmentPage = () => {
    const { t } = useTranslation();
    const { setIsLoading, isLoading } = useTkalDataContext();
    const [step, setStep] = useState(1);
    let steps = 1;
    const [protectiveEquipment, setProtectiveEquipment] = useState<ProtectiveEquipment>({} as ProtectiveEquipment);
    const [pic, setPic] = useState<string>("")
    const signatureCanvasRef = useRef<any>(null);
    const [signature, setSignature] = useState<string>(protectiveEquipment.signatureBase64 ?? "");

    const handleBackButton = () => {
        if (step >= 2) {
            setStep(step => step - 1)
        }
    }

    const validateLastStep = () => {
        if (step !== steps) {
            return false;
        }
        if (protectiveEquipment.workerDirectoCode === undefined) {
            return false;
        }
        if (protectiveEquipment.signatureBase64 === undefined) {
            return false;
        }
        return true;
    }

    const canSendTest = () => {
        if (protectiveEquipment.workerDirectoCode === undefined) {
            alert("Valige töötaja")
            return false;
        }
        if (protectiveEquipment.signatureBase64 === undefined) {
            alert("Allkiri on täitmata")
            return false;
        }
        return true
    }

    const handleSendTest = async () => {
        if (!canSendTest()) return;
        setIsLoading(true);
        try {
            await SendProtectiveEquipmentForm(protectiveEquipment);
            alert("Saadetud Directosse");
        } catch (err: any) {
            if (err.response) {
                console.error("Backend error:", err.response.data);
                alert(`Error: ${err.response.data.message || "An error occurred"}`);
            } else if (err.request) {
                console.error("No response from backend:", err.request);
                alert("Error: No response from server. Please try again.");
            } else {
                console.error("Error:", err.message);
                alert(`Error: ${err.message}`);
            }
        } finally {
            setIsLoading(false);
            setProtectiveEquipment({} as ProtectiveEquipment);
            setSignature("")
        }
    };

    const handleSaveSignature = () => {
        if (signatureCanvasRef.current === null) return;
        const signatureImage = signatureCanvasRef.current.toDataURL();
        setSignature(signatureImage);
        setProtectiveEquipment({ ...protectiveEquipment, signatureBase64: signatureImage })
        return;
    };

    const handleClearSignature = () => {
        setPic("")
        setSignature("")
        if (signatureCanvasRef.current === null) return;
        signatureCanvasRef.current.clear();
    };
    return (
        <div className="mainPageLayout">
            {
                (() => {
                    switch (step) {
                        case 1:
                            return (
                                <>
                                    <SelectPerson setProtectiveEquipment={setProtectiveEquipment} protectiveEquipment={protectiveEquipment} />
                                    {protectiveEquipment.workerName !== undefined &&
                                        <>
                                            <div>
                                                <Label className="mainPageForm-label" style={{ marginTop: "5%" }}>{t("Töötaja allkiri")}</Label>
                                            </div>
                                            <div style={{ marginTop: "1%", border: "1px solid #dee2e6", borderRadius: ".25rem", height: "200px", cursor: "pointer", width: 600 }}>
                                                {(pic !== "" && pic !== null && pic !== undefined) ?
                                                    <img src={`${pic}`} alt="Signature" />
                                                    :
                                                    <SignatureCanvas ref={signatureCanvasRef} canvasProps={{ height: 200, width: 600 }} backgroundColor='white' onEnd={handleSaveSignature} />
                                                }
                                            </div>
                                        </>
                                    }
                                </>
                            )
                    }
                })()
            }

            <div className="navigation-buttons-container" >
                {step >= 2 && <Button className="button-primary" onClick={handleBackButton}>{t("Back")}</Button>}
                {signature !== "" && <Button className="button-primary" onClick={handleClearSignature}>Tühjenda</Button>}
                {validateLastStep() && <Button className="button-primary" style={{ width: "170px", backgroundColor: "white", color: "red" }} disabled={isLoading} onClick={handleSendTest}>{t("ConfirmAndSend")} </Button>}
            </div>
        </div>
    )
}

export default ProtectiveEquipmentPage